<template>
    <div>
        <el-empty style="height: 100vh;width: 100vw;">
            <template slot="image">
                <i class="el-icon-loading" style="font-size: 100px;color: #ccc;"></i>

            </template>
            <template slot="description">

                <p style="font-size: 20px;color: #999;">正在加载...</p>
            </template>
        </el-empty>
    </div>
</template>
<script>
import TokenCache from "@/utils/cache/TokenCache";
import { set } from "nprogress";
export default {
    data() {
        return {};
    },
    props: {},
    components: {},
    methods: {},
    mounted() { },
    created() {

        if (this.$route.query.Id && this.$route.query.phone) {

            this.$http.post('/Base_ProjectManage/Project_ExternalUser/EUSubmitLoginByPhone', {
                ApprovalId: this.$route.query.Id,
                phone: this.$route.query.phone
            }).then(res => {
                if (res.Success) {
                    if (res.Data?.Token) {
                        TokenCache.setToken(res.Data.Token || '');
                        window.localStorage.setItem('RGInfo', JSON.stringify(res.Data.EUInfo))
                        this.$http.post('/ReviewGO/Review_ApprovalInfo/GetDataListByPhoneForUnreviewed', {
                            SortField: "CreateTime",
                            SortType: "desc",
                            "Search": [
                                {
                                    "Condition": "Id",
                                    "Keyword": this.$route.query.Id
                                },
                                {
                                    "Condition": "ApprovalPhone",
                                    "Keyword": this.$route.query.phone
                                }
                            ]
                        }).then(resData => {
                            if (resData.Success) {
                                if (resData.Data.length > 0) {
                                    let data = resData.Data[0]
                                    window.location.href = `${window.location.origin}/RG_ApprovalInfoDetail?Id=${data.Id}&num=0&ApprovalType=${data.ApprovalType}&TaskId=${data.TaskId}`
                                } else {
                                    this.$message.error('未找到审批信息,将跳转至首页')
                                    setTimeout(() => {
                                        this.$router.push({ path: '/RG_UserLogin' })
                                    }, 3000)
                                }

                            } else {

                                this.$message.error('请求失败,将跳转至首页')
                                setTimeout(() => {
                                    this.$router.push({ path: '/RG_UserLogin' })
                                }, 3000)
                            }
                        })
                    }

                } else {

                    this.$message.error('请求失败,将跳转至首页')
                    setTimeout(() => {
                        this.$router.push({ path: '/RG_UserLogin' })
                    }, 3000)
                }
            })
        } else {
            this.$router.push({ path: '/RG_UserLogin' })
        }


    },
    computed: {},
    watch: {},
};
</script>
<style lang='scss' scoped></style>